const dataSets = {
	randomWords: [
		"boast",
		"disobey",
		"limping",
		"men",
		"question",
		"milky",
		"handle",
		"skirt",
		"adorable",
		"apply",
		"marvelous",
		"teeny-tiny",
		"juice",
		"illuminate",
		"voyage",
		"camp",
		"bizarre",
		"sweltering",
		"bray",
		"cycle",
		"pet",
		"weak",
		"jump",
		"number",
		"chemical",
		"help",
		"elastic",
		"bow",
		"hook",
		"terrific",
		"humor",
		"eye",
		"well-off",
		"dive",
		"giddy",
		"girl",
		"spoil",
		"responsible",
		"slit",
		"fog",
		"impair",
		"capture",
		"frog",
		"contrast",
		"volleyball",
		"modify",
		"dear",
		"general",
		"save",
		"feeling",
		"craven",
		"fork",
		"faithful",
		"flimsy",
		"absent",
		"come",
		"tendency",
		"madly",
		"reading",
		"hurt",
		"omniscient",
		"two",
		"coach",
		"acrid",
		"bend",
		"snail",
		"heave",
		"available",
		"ants",
		"sticks",
		"excellent",
		"search",
		"seashore",
		"small",
		"damage",
		"have",
		"roar",
		"connote",
		"send",
		"grain",
		"fallacious",
		"farmer",
		"thunder",
		"develop",
		"prevent",
		"connection",
		"flawless",
		"houses",
		"creator",
		"shed",
		"wacky",
		"stomach",
		"vacuous",
		"cow",
		"buy",
		"abnormal",
		"feeling",
		"raspy",
		"crowded",
		"rain",
		"record",
		"string",
		"panicky",
		"faulty",
		"petite",
		"quit",
		"tank",
		"bean",
		"compete",
		"new",
		"trite",
		"five",
		"right",
		"wing",
		"parsimonious",
		"sip",
		"true",
		"stoop",
		"blushing",
		"animate",
		"delay",
		"squealing",
		"chemical",
		"summer",
		"belief",
		"jellyfish",
		"shirk",
		"kittens",
		"animal",
		"hear",
		"giraffe",
		"economic",
		"water",
		"evaporate",
		"yawn",
		"rise",
		"holistic",
		"use",
		"bear",
		"same",
		"spoil",
		"state",
		"measure",
		"lizards",
		"grip",
		"stride",
		"cynical",
		"moaning",
		"umbrella",
		"grieving",
		"decorous",
		"dive",
		"late",
		"mash",
		"hot",
		"berry",
		"shear",
		"dock",
		"tenuous",
		"door",
		"sun",
		"squeamish",
		"psychedelic",
		"scarf",
		"yummy",
		"crib",
		"steep",
		"catch",
		"lawyer",
		"slim",
		"lewd",
		"conserve",
		"grade",
		"itchy",
		"deadpan",
		"guitar",
		"ill-informed",
		"vulgar",
		"girls",
		"used",
		"travel",
		"plot",
		"iron",
		"overt",
		"squirrel",
		"bend",
		"burst",
		"exuberant",
		"fresh",
		"inculcate",
		"coherent",
		"next",
		"surmise",
		"frantic",
		"scarce",
		"earthy",
		"flee",
		"saunter",
		"school",
		"reuse",
		"stick",
		"trust",
		"puffy",
		"yarn",
		"nebulous",
		"merge",
		"thrive",
		"aunt",
		"craven",
		"learned",
		"calculator",
		"study",
		"illumine",
		"need",
		"opinion",
		"drop",
		"groovy",
		"melodic",
		"come",
		"paltry",
		"indulge",
		"metal",
		"curved",
		"sleepy",
		"pump",
		"touch",
		"church",
		"encourage",
		"chair",
		"weary",
		"lick",
		"receptive",
		"destruction",
		"shock",
		"frog",
		"fluffy",
		"touch",
		"rule",
		"apathetic",
		"cows",
		"silly",
		"feast",
		"drawer",
		"tomatoes",
		"prefer",
		"attract",
		"festive",
		"sow",
		"hope",
		"three",
		"corrod",
		"smelly",
		"succinct",
		"impose",
		"impartial",
		"attack",
		"ambitious",
		"discussion",
		"machine",
		"act",
		"die",
		"curb",
		"branch",
		"roar",
		"corrod",
		"cumbersome",
		"shaky",
		"well-to-do",
		"quickest",
		"aboriginal",
		"blossom",
		"apply",
		"floor",
		"anger",
		"smash",
		"fearful",
		"crowd",
		"output",
		"curious",
		"propose",
		"astonish",
		"puffy",
		"veil",
		"annoying",
		"wistful",
		"occur",
		"amuck",
		"grape",
		"crib",
		"leather",
		"substantial",
		"send",
		"questionable",
		"innovate",
		"advice",
		"seat",
		"glance",
		"bomb",
		"acceptable",
		"leer",
		"concerned",
		"water",
		"explain",
		"marble",
		"petite",
		"morning",
		"magnificent",
		"wistful",
		"point",
		"save",
		"next",
		"pour",
		"rabbits",
		"messy",
		"thank",
		"pluck",
		"cook",
		"temporary",
		"engrave",
		"wandering",
		"practise",
		"fulfil",
		"coil",
		"idea",
		"corrupt",
		"thrive",
		"incredible",
		"harmony",
		"fancy",
		"explore",
		"statement",
		"tax",
		"stretch",
		"fit",
		"oval",
		"ambitious",
		"evaporate",
		"violate",
		"disturb",
		"bid",
		"mist",
		"leg",
		"mother",
		"hushed",
		"owe",
		"adventurous",
		"nappy",
		"gifted",
		"muddled",
		"selection",
		"dangerous",
		"female",
		"mindless",
		"shaggy",
		"silver",
		"present",
		"cold",
		"omit",
		"warn",
		"own",
		"puzzled",
		"tear",
		"mash",
		"cows",
		"ducks",
		"victorious",
		"flock",
		"spread",
		"abstracted",
		"dogs",
		"cultured",
		"cracker",
		"deeply",
		"doctor",
		"thing",
		"sag",
		"disturb",
		"enthusiastic",
		"cover",
		"loaf",
		"smite",
		"tasteful",
		"lizards",
		"incise",
		"differ",
		"attractive",
		"spring",
		"maddening",
		"belligerent",
		"weather",
		"coast",
		"spotless",
		"complete",
		"jumpy",
		"choose",
		"cause",
		"nip",
		"stray",
		"recollect",
		"ski",
		"authority",
		"steam",
		"spend",
		"toothpaste",
		"mow",
		"swing",
		"tangy",
		"impede",
		"dispose",
		"interesting",
		"please",
		"ticket",
		"teach",
		"rhythm",
		"arise",
		"spotty",
		"cherry",
		"crazy",
		"prohibit",
		"sock",
		"chide",
		"cloudy",
		"paint",
		"arm",
		"internal",
		"dapper",
		"gigantic",
		"rigid",
		"mark",
		"scary",
		"competition",
		"wonderful",
		"insert",
		"moldy",
		"injure",
		"avoid",
		"person",
		"wind",
		"cable",
		"frantic",
		"float",
		"wandering",
		"dinner",
		"offer",
		"tap",
		"witty",
		"spotted",
		"taboo",
		"tremendous",
		"agreement",
		"branch",
		"open",
		"insect",
		"eatable",
		"depend",
		"abnormal",
		"seat",
		"size",
		"venomous",
		"scintillating",
		"expect",
		"title",
		"crush",
		"report",
		"prison",
		"corn",
		"vessel",
		"manager",
		"base",
		"tired",
		"amusing",
		"assert",
		"survive",
		"key",
		"gain",
		"refuse",
		"help",
		"sweater",
		"arm",
		"giraffe",
		"veil",
		"impartial",
		"underwear",
		"tray",
		"rustic",
		"make",
		"lean",
		"happen",
		"abashed",
		"wretched",
		"hollow",
		"imported",
		"unable",
		"strain",
		"light",
		"talk",
		"feed",
		"cute"
	],
};

export { dataSets };
