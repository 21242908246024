// shell.debounce
/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * @param cb {function} to call after delay logic
 * @param ms {Number} in milliseconds how long to delay for
 * @param callFirstThenThrottle {Boolean} if true will trigger the function on the leading edge instead of the trailing
 * @returns {Function}
 */
const debounce = function db(cb, ms, callFirstThenThrottle) {
	// abstracted delay from function.
	let delay;

	// return the function
	// eslint-disable-next-line func-names
	return function (...args) {
		// callback for setTimeout
		const delayCb = () => {
			delay = null;
			if (!callFirstThenThrottle) {
				cb.apply(this, args);
			}
		};

		// see if we should run it right now (leading edge)
		const run = callFirstThenThrottle && !delay;

		// clear previous timeouts
		window.clearTimeout(delay);

		// set the delay to check on the next debounce pass
		delay = window.setTimeout(delayCb, ms);

		// run leading edge.
		// doesn't need the redraw if it's immediate due to not having a setTimeout.
		if (run) cb.apply(this, args);
	};
};

debounce.docs = () => {
	return {
		params: {
			cb: {
				type: Function,
				required: true,
				description: 'Callback function you want debounced or throttled',
			},
			ms: {
				type: Number,
				description: 'Milliseconds to debounce/throttle',
			},
			callFirstThenThrottle: {
				type: Boolean,
				default: false,
				description: 'False = execute the callback after the function stops being called for the duration of millieconds defined (debouncing). True = this will execute the callback immediately then prevent the callback from firing again until the function stops being called for the duration of millieconds defined (throttling).',
			},
		},
	};
};

debounce.docsName = 'debounce';

export { debounce };
