<template>
  <div class="companies">
    <v-card class="list">
      <div class="tools">
        <v-text-field hide-details class="pr-2 pl-2" label="Filter Companies" prepend-icon="fal fa-search" v-model="filterCompanyString"></v-text-field>
      </div>
      <v-list-item-group color="primary" v-model="selectedCompanyIndex">
        <v-list-item v-for="company in companiesList" :key="company.companyID" @click="newCompanySelected(company)">
          {{ company.practiceName }}
        </v-list-item>
      </v-list-item-group>
    </v-card>
    <div class="pages" v-if="selectedCompanyCopy">
      <v-tabs
          v-model="activeTab"
          class="pages-tabs"
      >
        <v-tab>Company Data</v-tab>
        <v-tab>Landing Pages</v-tab>
        <v-tab>Users</v-tab>
      </v-tabs>
      <template>
        <div class="edit" v-if="activeTab === 0">
          <v-card
              class="col-12 step-card show-scrollbar"
          >
            <div class="scrolling">


              <div class="section-header">Physician/Practice Information</div>
              <!-- practice name -->
              <v-text-field
                  label="Physician/Practice Name"
                  v-model="selectedCompanyCopy.practiceName"
                  class="col-12"
                  outlined
                  dense
              />
              <div class="horizontal">
                <!-- practice name -->
                <v-text-field
                    label="Contact Name"
                    v-model="selectedCompanyCopy.practiceContactName"
                    class="col-6"
                    outlined
                    dense
                />
                <v-text-field
                    label="Contact Email"
                    v-model="selectedCompanyCopy.practiceContactEmail"
                    class="col-6"
                    outlined
                    dense
                />
              </div>
              <div class="horizontal">
                <v-text-field
                    type="number"
                    v-model="selectedCompanyCopy.practicePhone"
                    label="Office Phone"
                    class="col-6"
                    outlined
                    dense
                />
                <v-text-field
                    type="number"
                    label="Office Mobile Phone"
                    v-model="selectedCompanyCopy.practiceMobilePhone"
                    class="col-6"
                    outlined
                    dense
                />
                <v-text-field
                    label="Office Email"
                    v-model="selectedCompanyCopy.practiceEmail"
                    class="col-6"
                    outlined
                    dense
                />
              </div>
              <!-- practice street -->
              <v-text-field
                  v-model="selectedCompanyCopy.practiceStreet"
                  label="Street Address"
                  class="col-12"
                  outlined
                  dense
              />
              <div class="horizontal">
                <v-text-field
                    class="col-4"
                    label="City"
                    v-model="selectedCompanyCopy.practiceCity"
                    outlined
                    dense
                />
                <v-autocomplete
                    class="col-4"
                    v-model="selectedCompanyCopy.practiceState"
                    :items="states"
                    label="State"
                    persistent-hint
                    outlined
                    dense
                />
                <v-text-field
                    class="col-4"
                    label="Zip"
                    v-model="selectedCompanyCopy.practiceZip"
                    outlined
                    dense
                />

              </div>
              Office Hours:
              <div
                  v-for="day in days"
                  :key="day.value"
                  class="horizontal horizontal__checkbox"
              >
                  <span class="mr-2">{{ day.text }}:
                    <span class="hours"
                        v-if="selectedCompanyCopy.businessHours[day.value].open"
                    >
                      {{ selectedCompanyCopy.businessHours[day.value].start }} - {{ selectedCompanyCopy.businessHours[day.value].end }}
                    </span>
                    <span class="hours" v-else>
                      closed
                    </span>
                  </span>
                <div class="horizontal mr-2" v-if="checks[day.value]">
                  <v-text-field
                      type="number"
                      label="Start"
                      class="col-6"
                      v-model="selectedCompanyCopy.businessHours[day.value].start"
                      outlined
                      dense
                      hide-details
                  />
                  <v-text-field
                      type="number"
                      label="End"
                      class="col-6"
                      v-model="selectedCompanyCopy.businessHours[day.value].end"
                      outlined
                      dense
                      hide-details
                  />
                  <v-checkbox
                      label="open?"
                      v-model="selectedCompanyCopy.businessHours[day.value].open"
                  />
                </div>
                <v-icon @click="checks[day.value] = !checks[day.value]">
                  {{ checks[day.value] ? 'fal fa-check' : 'fal fa-edit' }}
                </v-icon>
              </div>
              <div class="section-header">Practice Providers and/or Facility Information</div>
              <div class="horizontal" :class="{
                  'mt-2': i !== 0,
                }" v-for="(provider, i) in selectedCompanyCopy.providers" :key="i">
                <v-text-field
                    label="Provider Name, Credentials"
                    v-model="provider.name"
                    class="col-4"
                    outlined
                    dense
                    hide-details
                />
                <v-text-field
                    label="NPI #"
                    v-model="provider.npi"
                    class="col-4"
                    outlined
                    dense
                    hide-details
                />
                <v-text-field
                    label="License #"
                    v-model="provider.license"
                    class="col-4"
                    outlined
                    dense
                    hide-details
                />
                <v-icon
                    @click="removeProvider(i, $event)"
                    class="ml-2"
                    v-if="i !== 0"
                >fal fa-times</v-icon>
              </div>
              <v-btn
                  outlined
                  icon
                  @click="addProvider"
                  class="add-more-btn"
              >
                <v-icon>fal fa-plus</v-icon>
              </v-btn>
              <div class="section-header">Insurance Information</div>
              <v-switch
                  v-model="selectedCompanyCopy.medicare"
                  label="Do you accept Medicare?"
              ></v-switch>
              Top 3 Procedures
              <div class="horizontal">
                <v-text-field
                    type="text"
                    label="Procedure"
                    class="col-4"
                    v-model="selectedCompanyCopy.procedure1"
                    outlined
                    dense
                    hide-details
                />
                <v-text-field
                    type="text"
                    label="Procedure"
                    class="col-4"
                    v-model="selectedCompanyCopy.procedure2"
                    outlined
                    dense
                    hide-details
                />
                <v-text-field
                    type="text"
                    label="Procedure"
                    class="col-4"
                    v-model="selectedCompanyCopy.procedure3"
                    outlined
                    dense
                    hide-details
                />
              </div>
              <div class="mt-3">Signature</div>
              <div class="info-signature">
                <div class="sig">{{selectedCompanyCopy.infoSignature.signatureName}}</div>
                <span class="date">({{selectedCompanyCopy.infoSignature.dateFormatted}})</span>
              </div>

              <div class="mt-3">Sales Rep</div>
              <div class="which-sales-rep">
                <v-select :items="salesReps" @input="$set(selectedCompanyCopy, 'salesRep', $event)" :value="selectedCompanyCopy.salesRep"/>
              </div>


            </div>
            <v-card-actions class="pt-4 pb-0">
              <div class="submit-btn flex flex-column">
                <v-btn
                    color="success"
                    @click="submitForm()"
                >
                  Save
                </v-btn>
              </div>

            </v-card-actions>

          </v-card>
        </div>
        <div class="landing-pages" v-if="activeTab === 1">
          <div class="heading">
            <h2>Google Form Parser</h2>
            <v-text-field v-model="currentGoogleForm" hide-details/>
            <div class="font-weight-bold" v-if="googleFormParsed.form">{{ googleFormParsed.form }}</div>
            <div class="font-weight-bold" v-if="googleFormParsed.tokens">{{ googleFormParsed.tokens }}</div>
          </div>


          <div class="heading">
            <h2>Landing Pages</h2>
            <v-btn small @click="addNewLandingPage">Add New</v-btn>
          </div>

          <v-card
              outlined
              class="landing-card" v-for="(landing, key) in landingPagesCopy" :key="key"
          >
            <v-card-title class="landing-card-title"><span>{{landingPages[key].landingPageID}}</span> <v-btn x-small color="error" @click="manuallyDeleteLandingPage(key)">Delete</v-btn></v-card-title>
            <v-list-item class="landing-card-item">
              <v-list-item-content>
                <div class="full-width">
                  <v-text-field
                      dense
                      label="URL ( emsbio.com/{THIS} )"
                      :error="errorLandingNames[landing.landingPageID]"
                      :error-messages="errorLandingNames[landing.landingPageID] ? 'Name used, please try another' : ''"
                      :value="landing.landingPageID"
                      @input="debouncedCheckLandingName(landing, $event)"
                  />
                </div>
                <div class="full-width">
                  <v-text-field dense label="Contact Number" v-model="landing.contactNumber"/>
                </div>
                <div class="full-width">
                  <v-text-field dense label="Google Form URL" v-model="landing.formURL"/>
                </div>
                <div class="full-width">
                  <v-text-field dense label="Google Form Email Field ID" v-model="landing.formEmailID"/>
                </div>
                <div class="full-width">
                  <v-text-field dense label="Google Form Name Field ID" v-model="landing.formNameID"/>
                </div>
                <div class="full-width">
                  <v-img class="logo-tool__image" :src="landing.logo"></v-img>
                  <v-file-input dense
                      accept="image/*"
                      label="Upload Logo"
                      v-model="landingPageLogosToUpload[key]"
                      @change="markKeyAsChanged(key)"
                  />
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn color="success" :disabled="!landingPagesWithChanges[key]" @click="saveLanding(key)">Save</v-btn>
              <v-btn color="error" :disabled="!landingPagesWithChanges[key]" @click="revertLandingPage(key)">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div class="users" v-if="activeTab === 2">
          <div class="heading">
            <h2>Users</h2>
          </div>
          <div class="user-container">
            <div class="list">
              <div class="tools">
                <v-btn color="primary" rounded @click="showNewUserForm = true">Create New User</v-btn>
              </div>
              <v-list-item-group color="primary" v-model="selectedCompanyUserIndex">
                <v-list-item class="order-item" v-for="u in usersByCompany" :key="u.userID"
                    @click="selectedCompanyUser = users[u.userID]">
                  <div class="">{{ u.name_first }} {{u.name_last}}</div>
                  <v-icon @click="deleteCompanyUser(u.userID)">fa fa-trash</v-icon>
                </v-list-item>
              </v-list-item-group>
            </div>
            <div class="new-user-form" v-if="showNewUserForm">
              <v-text-field
                  label="First Name"
                  v-model="newUser.name_first"
                  :error="newUserError"
                  @input="newUserError = false"
                  class="col-12"
                  outlined
                  dense
              />
              <v-text-field
                  label="Last Name"
                  v-model="newUser.name_last"
                  :error="newUserError"
                  @input="newUserError = false"
                  class="col-12"
                  outlined
                  dense
              />
              <v-text-field
                  label="Email"
                  v-model="newUser.email"
                  :error="newUserError"
                  @input="newUserError = false"
                  class="col-12"
                  outlined
                  dense
              />
              <v-text-field
                  label="Password"
                  v-model="newUser.password"
                  :error="newUserError"
                  @input="newUserError = false"
                  class="col-12"
                  outlined
                  dense
              >
                <template #append-outer>
                    <v-icon
                        class="fal fa-random fa-rotate-180 newPasswordGenerator"
                        @click="generateNewUserPassword"
                    >

                    </v-icon>
                </template>
              </v-text-field>
              <div class="errorMessage font-red" v-if="newUserError">
                {{newUserErrorMessage}}
              </div>
              <v-btn
                  :disabled="newUserError"
                  color="success"
                  @click="createNewCompanyUser()"
              >
                Save New User
              </v-btn>
            </div>
          </div>


<!--          <v-card-->
<!--              outlined-->
<!--              class="landing-card" v-for="(landing, key) in landingPagesCopy" :key="key"-->
<!--          >-->

<!--            <v-card-actions>-->
<!--              <v-btn color="success" :disabled="!landingPagesWithChanges[key]" @click="saveLanding(key)">Save</v-btn>-->
<!--              <v-btn color="error" :disabled="!landingPagesWithChanges[key]" @click="revertLandingPage(key)">Cancel</v-btn>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { storage, analytics, db } from "@/helpers/firebase";
import { debounce } from "@/helpers/debounce";
import { dataSets } from "@/helpers/dataSets";
import { uuid } from "@/helpers/uuid";

const storageRef = storage.ref();

export default {
  name: 'Companies',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'EMS Bio Companies',
    meta: [
      {
        name: 'description',
        content: 'Manage Companies'
      },
    ],
    // all titles will be injected into this template
    titleTemplate: '%s'
  },
  docs(v) {
    return {
      props: v.props,
      events: {},
      slots: {},
      components: v.components,
    };
  },
  props: {},
  data() {
    return {
      showNewUserForm: false,
      newUserError: false,
      newUser: {
        name_first: '',
        name_last: '',
        email: '',
        password: '',
      },
      selectedCompanyUserIndex: null,
      activeTab: '0',
      currentGoogleForm: '',
      selectedCompany: null,
      selectedCompanyUser: null,
      selectedCompanyCopy: null,
      selectedCompanyIndex: null,
      filterCompanyString: '',
      companies: {},
      users: {},
      landingPages: {},
      landingPagesCopy: {}, // so we can diff if there was changes
      // quick way to know if they've chosen a bad landing name
      errorLandingNames: {},
      landingPagesWithChanges: {},
      landingPageLogosToUpload: {},
      newLandingPageTemplate: {
        companyID: '',
        contactNumber: '',
        formEmailID: '',
        formNameID: '',
        formURL: '',
        landingPageID: '',
        logo: '',
      },
      checks: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
      debouncedCheckLandingName: debounce((current, wanting) => {
        this.checkLandingPageAvailability(current, wanting)
      }, 350),
      passwordHelpers: dataSets.randomWords,
    };
  },
  created() {
    db.collection("companies")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            this.$set(this.companies, doc.id, doc.data());
          });
        })
        .catch(function(error) {
          console.log("Error getting companies: ", error);
        });

    this.getUsers()
  },
  methods: {
    getUsers() {
      db.collection("users")
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              // doc.data() is never undefined for query doc snapshots
              this.$set(this.users, doc.id, doc.data());
            });
          })
          .catch(function(error) {
            console.log("Error getting users: ", error);
          });
    },
    isValidEmail(emailAddress) {
      const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      return pattern.test(emailAddress);
    },
    randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    generateNewUserPassword() {
      const randomIndex1 = this.randomIntFromInterval(0, 499);
      const randomIndex2 = this.randomIntFromInterval(0, 499);
      let generatedString = this.passwordHelpers[randomIndex1];

      generatedString += "-" + Math.random().toString(36).slice(-8) + '-';

      generatedString += this.passwordHelpers[randomIndex2];

      this.newUser.password = generatedString;
    },
    newCompanySelected(company) {
      this.selectedCompany = company;
      this.selectedCompanyUser = null;
      this.selectedCompanyUserIndex = null;
    },
    resetNewUser() {
      this.$set(this, 'newUser', {
        name_first: '',
        name_last: '',
        email: '',
        password: '',
        companyID: '',
        userID: '',
      });
    },
    deleteCompanyUser(userID) {
      const doit = window.confirm('Are you sure you want to delete this company user?  There\'s no going back.');

      if (!doit) return;

      db.collection('users')
          .doc(userID)
          .delete()
          .then(() => {
            this.$delete(this.users, userID);
            analytics.logEvent('company user deleted');
          });
    },
    createNewCompanyUser() {

      if (!this.newUser.name_first || !this.newUser.name_last || !this.newUser.password) {
        this.newUserError = true;
        this.newUserErrorMessage = 'All Fields Required';
        return;
      }

      if (!this.isValidEmail(this.newUser.email)) {
        this.newUserError = true;
        this.newUserErrorMessage = 'Invalid Email';
        return;
      }

      this.$set(this.newUser, 'companyID', this.selectedCompany.companyID);
      // this gets changed
      this.$set(this.newUser, 'userID', uuid());

      db.collection('users')
          .doc(this.newUser.userID)
          .set(this.newUser)
          .then(() => {
            this.newUser = {};
            this.resetNewUser();
            window.setTimeout(() => {
              this.getUsers()
            }, 2000)
            analytics.logEvent('new company user created');
          });

    },
    markKeyAsChanged(key) {
      this.$set(this.landingPagesWithChanges, key, true);
    },
    manuallyDeleteLandingPage(key) {
      const doIt = window.confirm('Are you sure you want to delete this landing page and logo');

      if (!doIt) return;
      this.deleteLandingPage(key, true)
          .then(() => {
            this.$delete(this.landingPagesCopy, key);
            this.$delete(this.landingPageLogosToUpload, key);
          })
    },
    deleteLandingPage(key, deleteLogo = false) {
      return db.collection('landingPages')
          .doc(key)
          .delete()
          .then(() => {
            if (deleteLogo) {
              storageRef.child('logos/' + this.landingPages[key].fileTag)
              .delete()
              .catch(err => {
                console.error('failed to delete logo', err);
              })
            }
            delete this.landingPages[key];
          })
          .catch(err => {
            console.log('Problem deleting landing page');
            console.error(err);
          });
    },
    async saveLanding(key) {
      const fileTag = new Date().getTime();
      const toSave = this.landingPagesCopy[key];

      if (!toSave.fileTag) {
        toSave.fileTag = fileTag;
      }

      const newKey = toSave.landingPageID;

      if (this.landingPageLogosToUpload[key]) {
        const child = storageRef
            .child('logos/' + fileTag);

        await child
            .put(this.landingPageLogosToUpload[key])
            .then(async () => {
              // remove the file so we don't try and upload again on a new save
              this.$delete(this.landingPageLogosToUpload, key);
              analytics.logEvent(`uploadedLogo`);
            });

        await child.getDownloadURL().then(url => {
          toSave.logo = url;
        })
      }

      await db.collection('landingPages')
          .doc(`${newKey}`)
          .set(toSave, { merge: true })
          .then(async () => {
            // if they changed the url key we need to remove the old one
            // not type checking on purpose
            if (newKey != key) {
             await this.deleteLandingPage(key);
            }

            this.$set(this.landingPages, newKey, this.landingPagesCopy[key])

            this.$delete(this.landingPagesCopy, key);

            this.$set(this.landingPagesCopy, newKey, JSON.parse(JSON.stringify(this.landingPages[newKey])))

            analytics.logEvent(`updatedLandingPage`);
          })

    },
    addNewLandingPage() {
      const newKey = new Date().getTime();
      const copyTemplate = JSON.parse(JSON.stringify(this.newLandingPageTemplate));
      copyTemplate.landingPageID = newKey;
      copyTemplate.companyID = this.selectedCompany.companyID;
      const copyTemplateCopy = JSON.parse(JSON.stringify(this.newLandingPageTemplate));
      copyTemplateCopy.landingPageID = newKey;
      copyTemplateCopy.companyID = this.selectedCompany.companyID;
      this.$set(this.landingPages, newKey, copyTemplate);
      this.$set(this.landingPagesCopy, newKey, copyTemplateCopy);
      this.$set(this.landingPageLogosToUpload, newKey, []);
    },
    revertLandingPage(key) {
      this.$set(this.landingPagesCopy, key, JSON.parse(JSON.stringify(this.landingPages[key])));
    },
    checkForLandingPagesWithChanges() {
      Object.keys(this.landingPages).forEach(landingPageKey => {

        const original = JSON.stringify(this.landingPages[landingPageKey]);
        const copy = JSON.stringify(this.landingPagesCopy[landingPageKey]);

        if (original !== copy) {
          this.$set(this.landingPagesWithChanges, landingPageKey, true);
        } else {
          this.$delete(this.landingPagesWithChanges, landingPageKey);
        }
      })
    },
    checkLandingPageAvailability(current, wanting) {
      if (!wanting || current.landingPageID === wanting) {
        return this.$delete(this.errorLandingNames, current.landingPageID);
      }

      db.collection("landingPages").doc(wanting)
          .get()
          .then(doc => {
            const data = doc.data();
            if (data) {
              this.$set(this.errorLandingNames, current.landingPageID, true);
            } else {
              this.$delete(this.errorLandingNames, current.landingPageID);
              this.$set(current, 'landingPageID', wanting);
            }
          })
          .catch(function(error) {
            console.log("Error getting landingPages: ", error);
          });
    },
    setLandingsByCompanyID() {
      if (!this.selectedCompany?.companyID) return;

      // reset landing page helpers
      this.$set(this, 'landingPages', {});
      this.$set(this, 'landingPagesCopy', {});
      this.$set(this, 'errorLandingNames', {});
      this.$set(this, 'landingPagesWithChanges', {});
      this.$set(this, 'landingPageLogosToUpload', {});

      db.collection("landingPages")
          .where('companyID', '==', this.selectedCompany.companyID)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              // doc.data() is never undefined for query doc snapshots
              const data = doc.data();
              if (!data.landingPageID) data.landingPageID = doc.id;
              this.$set(this.landingPages, doc.id, data);
              this.$set(this.landingPagesCopy, doc.id, JSON.parse(JSON.stringify(data)));
              this.$set(this.landingPageLogosToUpload, doc.id, []);
            });
          })
          .catch(function(error) {
            console.log("Error getting landingPages: ", error);
          });
    },
    removeProvider(i) {
      this.selectedCompanyCopy.providers.splice(i, 1);
    },
    addProvider() {
      this.selectedCompanyCopy.providers.push({
        name: '',
        npi: '',
        license: '',
      })
    },
    submitForm() {

      const date = new Date();
      this.selectedCompanyCopy.companyUpdated = date.toISOString();

      db.collection('companies')
          .doc(this.selectedCompanyCopy.companyID)
          .set(this.selectedCompanyCopy, { merge: true })
          .then(() => {
            this.$set(this.companies, this.selectedCompanyCopy.companyID, this.selectedCompanyCopy);
            analytics.logEvent('company_updated');
          })

    },
  },
  computed: {
    usersByCompany() {
      return Object.keys(this.users)
          .filter(userK => this.users[userK].companyID === this.selectedCompany.companyID)
          .map(userK => this.users[userK])
          .sort((a, b) => a - b);
    },
    googleFormParsed() {
      const parsed = {
        form: '',
        tokens: '',
      };

      // example:
      // https://docs.google.com/forms/d/e/1FAIpQLSd1YBCr6RcVicTkjuiZpkw8WFog_OKLM1lNTi8c3HEw1Lmv6g/viewform?usp=pp_url

      const parse1 = this.currentGoogleForm.split('&entry.');

      if (parse1.length > 1) {
        parsed.form = parse1[0];
        parsed.tokens = parse1.map((token, i) => i === 0 ? '' : '&entry.' + token).join('');
      }

      return parsed;
    },
    salesReps() {
      const toReturn = [];
      Object.keys(this.users).forEach(userKey => {
        const val = this.users[userKey];
        if (!val.sales) return;
        toReturn.push({
          text: `${val.name_first} ${val.name_last}`,
          value: val.userID,
        });
      })

      // add empty option
      toReturn.unshift({
        text: '',
        value: '',
      })
      return toReturn;
    },
    companiesList() {
      const companies = Object.keys(this.companies).map(company => this.companies[company]);

      if (this.filterCompanyString) {
        return companies.filter(company => {
          return company.practiceName?.toLowerCase().includes(this.filterCompanyString?.toLowerCase());
        })
      } else {
        return companies;
      }
    },
    states() {
      return [
        'Alabama', 'Alaska', 'American Samoa', 'Arizona',
        'Arkansas', 'California', 'Colorado', 'Connecticut',
        'Delaware', 'District of Columbia', 'Federated States of Micronesia',
        'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
        'Louisiana', 'Maine', 'Marshall Islands', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
        'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
        'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico',
        'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
        'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia',
        'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
      ]
    },
    days() {
      return [
        {
          text: 'Monday',
          value: 'mon',
        },
        {
          text: 'Tuesday',
          value: 'tue',
        },
        {
          text: 'Wednesday',
          value: 'wed',
        },
        {
          text: 'Thursday',
          value: 'thu',
        },
        {
          text: 'Friday',
          value: 'fri',
        },
        {
          text: 'Saturday',
          value: 'sat',
        },
        {
          text: 'Sunday',
          value: 'sun',
        },
      ];
    },
  },
  watch: {
    selectedCompany: {
      deep: true,
      handler() {
        this.selectedCompanyCopy = JSON.parse(JSON.stringify(this.selectedCompany));
        this.currentGoogleForm = '';
        this.setLandingsByCompanyID();
      }
    },
    landingPagesCopy: {
      deep: true,
      handler() {
        this.checkForLandingPagesWithChanges();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.font-red {
  color: red;
}
.user-container {
  display: flex;
  .new-user-form {
    //margin-left: 2rem;
  }
}
.list {
  height: 100%;
  width: 300px;

  .tools {
    display: flex;
    align-items: center;

    .add-btn {
      margin: 0 1rem;
    }
  }
}
.pages-tabs {
  margin-bottom: 1rem;
}
.companies {
  display: flex;
  padding-top: 1rem;
  .list {
    width: 400px;
    margin-right: 2rem;
		max-height: calc(100vh - 82px);
		overflow: auto;
  }
}
.add-more-btn {
  margin-top: .5rem;
}
.horizontal {
  display: flex;
  align-items: center;

  .v-input:not(:first-child) {
    margin-left: 1rem;
  }

  &__checkbox {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: .5rem 0;

    .hours {
      font-weight: bold;
    }
  }
}
.step-card {
  margin-bottom: 1rem;
  overflow: auto;
  padding: 1rem;

  .section-header {
    font-size: 20px;
    margin-bottom: 1rem;
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
}
.info-signature {
  display: flex;
  align-items: flex-end;
  .sig {
    font-family: cursive;
    font-size: 40px;
    margin-right: 1rem;
    line-height: 1;
  }
  .date {
    font-size: 16px;
    color: grey;
  }
}
.scrolling {
  overflow: auto;
  max-height: calc(100vh - 200px);
  padding: 1rem;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}
.landing-pages {
  margin: 0 2rem;
  flex: 1;
  .landing-card {
    padding: 0 1rem;
    &:not(:first-child) {
      margin-top: 1rem;
    }
    .landing-card-title {
      display: flex;
      justify-content: space-between;
    }
    .full-width {
      width: 100%;
    }
    .logo-tool {
      flex: 1;
      width: 100%;
      &__image {
        max-width: 100px;
      }
    }
  }
}
</style>
